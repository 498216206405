// eslint-disable-next-line
import React from 'react';

export function msalGetToken(instance, resourceGuiId, callback) {
  return new Promise((resolve, reject) => {
    var request = {
      scopes: [`${resourceGuiId}/.default`]
    };
    instance.acquireTokenSilent(request).then((response) => {
      resolve(response['accessToken']);
    })
    .catch(function(e) {
      console.error(e);
      reject({ e });
    });
  });
}

export function runWithMsal(instance, app, doNotLogin) {
  instance.handleRedirectPromise().then(function() {
    if (doNotLogin) {
      app();
    } else {
      const currentAccounts = instance.getAllAccounts();
      if (currentAccounts.length === 0) {
        const loginRequest = {
          scopes: ["openid", "profile", "user.read"]
        };
        instance.loginRedirect(loginRequest);
      } else {
        instance.setActiveAccount(currentAccounts[0]);
        app();
      }
    }
  }).catch(function(e) { console.error(e); });
}